.message-left {
  width: 100%;
}

.message-user {
  font-size: 0.8em;
  text-align: left;
  margin: 0;
  margin-top: 0.4em;
}

.message-data {
  background-color: whitesmoke;
  padding: 0.5em;
  margin: 0;
  display: inline;
  text-align: left;
  max-width: 50%;
  float: left;
  word-break: break-word;
  border-radius: 1em;
}

.dark-mode .message-data {
  background-color: #25292c;
}

.message-right {
  width: 100%;
}

.message-right .message-user {
  text-align: right;
}

.message-right .message-data {
  text-align: right;
  background-color: #1990ff;
  color: white;
  float: right;
}

.message-left::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.message-right::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.new-messages-btn{
  position: absolute;
    top: -3em;
    background-color: #1990ff;
    color: white;
    box-shadow: 0 0 6px 2px #2196f359;
    cursor: pointer;
    padding: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 1em;
    font-weight: bold;
    border: none;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 1em;
}