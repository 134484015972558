@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-button {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #525252;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #363636;
}

::-webkit-scrollbar-thumb:active {
  background: #1a1a1a;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #878787;
}

::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

input[type="text"] {
  border-radius: 0.2rem !important;
}

input[type="text"]:focus {
  outline: none !important;
  -webkit-appearance: none !important;
}

.btn {
  box-shadow: none !important;
  border-radius: 0.2rem !important;
  font-weight: bold !important;
}

.card {
  padding: 2rem !important;
  border-radius: 0.2rem !important;
  border: 1px solid rgb(175 175 175 / 20%) !important;
}

.card-title {
  margin-bottom: 1em !important;
}

.navbar {
  padding: 3rem !important;
  border: none !important;
  backdrop-filter: blur(20px);
  background-color: #ffffffc4 !important;
}

.dark-mode .navbar {
  background-color: #26282cba !important;
}

.navbar-brand img,
.sidebar-brand img {
  height: 2.6rem !important;
}
