.logo {
  display: block;
}

.dark-mode .logo {
  display: none;
}

.logo-dark {
  display: none;
}

.dark-mode .logo-dark {
  display: block;
}

.logo-small {
  display: none;
}

@media (max-width: 600px) {
  .logo {
    display: none;
  }

  .dark-mode .logo {
    display: none;
  }

  .logo-dark {
    display: none;
  }

  .dark-mode .logo-dark {
    display: none;
  }

  .logo-small {
    display: block;
  }
}

.logo-vertical {
  display: block;
}

.dark-mode .logo-vertical {
  display: none;
}

.logo-dark-vertical {
  display: none;
}

.dark-mode .logo-dark-vertical {
  display: block;
}
