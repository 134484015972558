.video-title > p {
  margin: 0;
  font-weight: bold;
}

.video-channel {
  margin-top: 0.5em;
  font-size: 0.9em;
  font-weight: 500;
}

.video-thumbnail-wrapper {
  background: transparent;
  box-sizing: border-box;
  overflow: auto;
  max-width: 100%;
  height: calc(100% - 16px);
  overflow: hidden;
}

.video-thumbnail {
  width: 100%;
  padding-bottom: 56%;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}
