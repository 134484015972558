.main-container {
  display: flex;
  height: 100vh;
  min-height: 100vh;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.startup-top {
  width: 90%;
  height: 4em;
  background: #191d21;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.startup-main {
  display: flex;
  flex-direction: row;
}

.startup-container {
  background-color: #191d21;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  padding: 3em;
  margin: 5px;
}

.startup-heading {
  font-weight: bold;
  color: white;
  font-size: 2em;
  text-align: center;
}

.side-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../images/startupImage@4x.png);
}

.form-container {
  width: 60%;
  margin: auto;
  margin-top: 16vh;
  margin-bottom: 20vh;
  background-color: white;
  padding: 3em;
  border-radius: 20px;
  box-shadow: 0 0 20px 9px #00000059;
}

.dark-mode .form-container {
  background-color: #1e2225;
}

.mode-selector {
  margin-top: 4em;
  margin-bottom: 1em;
}

.mode {
  margin-right: 8px;
  cursor: pointer;
  padding: 1em;
  background-color: transparent;
  color: inherit;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 1em;
  font-weight: bold;
}

.mode-selected {
  background-color: #1990ff;
  color: white;
  box-shadow: 0 0 6px 2px #2196f359;
}

.bottomRow {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  font-size: 0.8em;
}

.bottomRow button {
  margin-right: 8px;
}

.bottomRow a {
  margin-right: 8px;
}

.bottomRow span {
  margin-right: 8px;
  font-weight: bold;
}

.submit-button {
  border-radius: 99999px;
  color: white;
  font-weight: bold;
  display: block;
  margin: auto;
  margin-top: 2em;
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  background-color: #1990ff;
  color: white;
  box-shadow: 0 0 6px 2px #2196f359;
}

.submit-button:hover {
  background-color: #1990ff;
  color: white;
}

.startup-label {
  margin-left: 0.1em;
  margin-top: 0.4em;
  font-weight: 400;
  margin-bottom: 0.2em !important;
}

.startup-switch {
  margin-top: 1em;
  margin-bottom: 1em;
}

.attribution-container {
  padding: 1em;
  border-top: 1px solid #d6d6d6;
}

.dark-mode .attribution-container {
  border-top: 1px solid #373a3d;
}

@media (max-width: 577px) {
  .form-container {
    width: 100vw;
    height: 100vh;
    box-shadow: none;
    margin-top: 0;
    border-radius: 0;
    padding-top: 20%;
  }

  .submit-button {
    width: 100%;
  }

  .attribution-container {
    display: none;
  }

  .startup-main{
    flex-direction: column;
  }
}

/* Instructions */
.instruction {
  display: flex;
  flex-direction: row;
  max-width: 14em;
  text-align: left;
}

.instruction-divider {
  border-bottom: 0.5px solid #313940;
  margin: 1em;
}

.instruction-title {
  display: block;
  text-align: left;
  font-weight: bold;
  font-size: 1.1em;
}

.instruction-number {
  height: 1.5em;
  width: 1.5em;
  font-size: 1.2em;
  background-color: #c33b76;
  line-height: 1.5em;
  font-weight: bold;
  border-radius: 50%;
  margin-right: 10px;
  text-align: center;
}
