.custom-footer {
  background-color: whitesmoke;
  padding: 1rem;
  border-top: 1px solid #d6d6d6;
}

.custom-footer-link {
  background-color: transparent;
  color: #1890ff;
  text-decoration: none;
  cursor: pointer;
}

.custom-footer-link:hover {
  text-decoration: underline;
}

.dark-mode .custom-footer {
  background-color: #191d21;
  padding: 1rem;
  border-top: 1px solid #373a3d;
}
