.color-strip {
  width: 100%;
  height: 8px;
  background: rgb(131, 58, 180);
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  );
}

.bottom-row {
  margin-left: 20px;
  margin-bottom: 20px;
  display: flex;
  font-size: 0.8em;
  position: relative;
  bottom: 0;
  left: 0;
}

.bottom-row button {
  margin-right: 8px;
}

.bottom-row a {
  margin-right: 8px;
}

.bottom-row span {
  margin-right: 8px;
  font-weight: bold;
}

.mobile-only {
  display: none;
}

.dark-mode .dark-mode-button {
  background-color: transparent !important;
  color: white;
}

.search-button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-weight: bold;
}

@media (max-width: 992px) {
  .mobile-only {
    display: block;
  }
}
