.video-list-item {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1em;
  position: relative;
  margin-right: 1em;
}

.video-list-item p {
  margin: 0;
}

.video-list-container {
  display: grid;
  grid-template-columns: 9fr 1fr;
}

.video-list-remove {
  background-color: #1990ff;
  color: white;
  padding: 0.4em;
  line-height: 1em;
  border-radius: 0.2em;
  display: inline-table;
  text-align: center;
  cursor: pointer;
}
